<template>
  <div class="fx-main mg-1-top fx fx-wrap wd-100 default-border">
    <div class="fx fx-main wd-100 fx-a-i-cent cart-item">
      <div class="dsp-grid gd-tp-c-3 wd-100 pd-05-em green-shadow fx-a-i-cent">
        <div>
          <!-- <router-link
            class="container-product"
            :to="{ name: 'p-detail', params: { product_id: cartItem.product.id, product_name: cartItem.product.name }, query: { supplier_id: this.cartItem.product.supplier_id } }"
          > -->

          <router-link
            class="container-product"
            :to="{ name: 'p-detail', params: { product_id: cartItem.product.id, product_name: product_url_name } }"
          >
            <div class="fx-center cart-p-img">
              <img
                :src="buildImgUrl(cartItem)"
                :alt="cartItem.product ? cartItem.product.name : ''"
              />
            </div>
          </router-link>
        </div>

        <div class="fx fx-main f-j-c-c cart-block-p-name-price fx-a-i-c fx-start">
          <div class="product-name full-width">
            <router-link
              :to="{ name: 'p-detail', params: { product_id: cartItem.product.id, product_name: product_url_name } }"
            >{{ cartItem.product.name }}</router-link>
          </div>

          <!-----------Plotted Price ----------------------------------------->
          <div v-if="cartItem.product.plotted_price" class="product-name full-width">
              <span class="price-number">
              {{ cartItem.unit_price | toCurrency }} à vista no boleto/PIX com desconto
              </span> 
              <br>
                
                <strong>
                  {{ max_installments_plotted }}x de 
                  {{ (cartItem.product.plotted_price / max_installments_plotted) | toCurrency }} sem juros no cartão
                  </strong>
              <!-- <span class="price-number ">
                {{ cartItem.product.plotted_price | toCurrency  }} 
              </span> -->
          </div>
          <!-----------Plotted Price ----------------------------------------->

          <div class="cart-qty">
            <span>{{ cartItem.quantity }}</span>
          </div>

          <div class="cart-price">
            <span class="product-price">x</span>
          </div>
          <div class="cart-price">
            <span class="product-price">{{ cartItem.unit_price | toCurrency }}</span>
          </div>
          
          <div class='variation-text'>
            <template v-if="cartItem.variation">
              <span>
                {{ cartItem.variation.name }}
              </span>
                </template>
          </div>
          
        </div>

        <div class="fx fx-start cart-buttons fx-a-i-c">
          <div
            class="fx fx-center clickable remove-iten"
            v-on:click="remover"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </div>
          <div
            class="clickable fx fx-center add-iten mg-l-r-1-em"
            @click="addOne()"
            v-if="Number(cartItem.quantity) < Number((cartItem.variation || cartItem.product).stocked_quantity)"
          >
            <i class="fas fa-plus"></i>
          </div>
          <div
            class="clickable fx fx-center less-quantity"
            @click="removeOne()"
            v-if="Number(cartItem.quantity) > 1"
          >
            <i class="fas fa-minus"></i>
          </div>
        </div>

        <!-- <select
            v-if="Number((cartItem.variation || cartItem.product).stocked_quantity) < 25"
            v-on:change="updateCartQuantity($event.target.value)"
          >
            <option
              v-for="q in Number((cartItem.variation || cartItem.product).stocked_quantity)"
              :key="q"
              :selected="q == cartItem.quantity"
            >{{ q }}</option>
          </select>
          <input
            v-else
            type="number"
            :value="cartItem.quantity"
            v-on:change="updateCartQuantity($event.target.value)"
        />-->
      </div>
    </div>
  </div>
</template>

<style scoped>






.cart-product-name span,
.manage-product-name span {
  word-wrap: break-all;
}

.fa-trash {
  color: #c92323c4;
}

@media (max-width: 500px) {
  /* .fx-center.fx-direct-cont.cart-buttons * {
    margin: 0 2em;
  }
  .gd-tp-c-3 {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    width: 100%;
    grid-column-end: span 2;
  }
  .fx-center.fx-direct-cont.cart-buttons {
    flex-direction: row;

    grid-column-end: span 100;
  }
  .cart-buttons {
    justify-content: flex-start;
  } */
}






.fx.fx-main.f-j-c-c.cart-block-p-name-price.fx-a-i-c.fx-start div {
    margin-right: 1em;
}

.product-name.full-width {
    width: 26em;
}

</style>



<script>

import { debounce } from "debounce";
import util from "@/services/util";
import Product from "@/services/product";
import Cart from "@/services/cart";
import { buildImgUrl } from "@/services/item";
import slugify from "slugify";
import Features from "@/services/features";

export default {
  name: "CartItem",
  props: ["cartItem", "remover"],
  data: function() {
    return {
      app_name: process.env.VUE_APP_NAME,
      myCartItem: this.cartItem,
      max_installments_plotted: null,
    };
  },
  mounted() {
    Features.get()
      .then((features) => {
        this.max_installments_plotted =
        features.payment_methods && features.payment_methods.credit_card
          ? Number(features.payment_methods.credit_card.maximum_installments_plotted)
          : null;
      });
    Product.get(this.myCartItem.product.id, {
      forAmount: this.myCartItem.quantity
    }).then(product => {
      this.myCartItem.product.price = product.price;
    });
  },
  computed: {
    product_url_name: function () {
      return slugify(this.cartItem.product.name);
    }
  },
  methods: {
    removeOne: function() {
      this.updateCartQuantity(Number(this.myCartItem.quantity) - 1);
    },
    addOne: function() {
      this.updateCartQuantity(Number(this.myCartItem.quantity) + 1);
    },
    updateCartQuantity: debounce(
      function(newQuantity) {
        let oldQuantity = this.myCartItem.quantity;
        let delta = newQuantity - oldQuantity;

        if (delta == 0) {
          // No changes
          return;
        }

        Product.get(this.myCartItem.product.id, {
          forAmount: newQuantity
        }).then(product => {
          this.myCartItem.product.price = product.price;
        });

        let nml = Math.abs(delta);

        if (delta > 0) {
          Cart.add(
            this.myCartItem.product.id,
            nml,
            this.myCartItem.variation
          ).then(
            response => {
              this.$root.$emit("cartUpdate");
              // Increased quantity
              this.myCartItem.quantity = newQuantity;
            },
            errorResponse => {
              // No change
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro ao Modificar Carrinho",
                text: util.stringifyAxiosError(errorResponse)
              });
            }
          );
        } else {
          Cart.remove(
            this.myCartItem.product.id,
            nml,
            this.myCartItem.variation
          ).then(
            response => {
              this.$root.$emit("cartUpdate");
              // Decreased quantity
              this.myCartItem.quantity = newQuantity;
            },
            errorResponse => {
              // No change
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro ao Modificar Carrinho",
                text: util.stringifyAxiosError(errorResponse)
              });
            }
          );
        }
      },
      400,
      false
    ),
    buildImgUrl: function(item) {
      return buildImgUrl(item);
    }
  }
};
</script>
