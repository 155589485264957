import HeaderBuilder from '@/services/header-builder.js';
import axios from 'axios';
import {
    apiBaseUrl
} from "@/services/api.js";
import store from '@/store';
import util from "@/services/util";
const Order = {
     list: async (searchOptions = {}) => {

        searchOptions = {...searchOptions };

        var builtSearchOpts = {};
        if (searchOptions.supplier_id) builtSearchOpts.supplier_id = searchOptions.supplier_id;
        const params = new URLSearchParams(util.cleanObject(searchOptions));

        const response = await axios.get(`${apiBaseUrl}/api/order?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },

    get: async (id) => {
        const response = await axios.get(`${apiBaseUrl}/api/order/${id}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    submitComment: async (id, text, supplier_id) => {
        const response = await axios.post(
            `${apiBaseUrl}/api/order/${id}/comment`, {
                text: text,
                supplier_id: supplier_id,
            }, {
                headers: HeaderBuilder.build()
            });
        return response.data;
    },
    updateStatus: async (id, new_status, data = {}) => {
        const response = await axios.patch(
            `${apiBaseUrl}/api/order`, {
                ...{
                    id: id,
                    sub_status: new_status
                },
                ...data
            }, {
                headers: HeaderBuilder.build()
            });
        return response.data;
    },
    place: async (data = {}) => {
        const is_local_cart = !store.state.user.logged;
        const response = await axios.post(
            `${apiBaseUrl}/api/order`, {
                ...data,
                cart: is_local_cart ? store.state.user.localCart : undefined,
            }, {
                headers: HeaderBuilder.build()
            });
        return response.data;
    },
    confirm: async (id) => {
        const response = await axios.put(`${apiBaseUrl}/api/order/${id}`, null, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    servicesLabel: async (id) => {
        const response = await axios.get(`${apiBaseUrl}/api/order-services/${id}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
};

export default Order;