import { apiBaseUrl,  apiBaseUrl1} from "@/services/api.js";

export function buildImgUrl(item) {
    if(!item || !item.product) {
        return "";
    }
    const real_images_array = item.variation && !(item.variation.parent_fields || []).includes('images') ? item.variation.images : item.product.images;
    if(!real_images_array || real_images_array.length <= 0) {
        return "";
    }
    return `${apiBaseUrl1}/api/product/${item.product.id}/image/${real_images_array[0]}?apartment=${process.env.VUE_APP_TENANT_ID}` + (item.variation && real_images_array == item.variation.images ? `&variation_id=${item.variation.id}` : "");
}
